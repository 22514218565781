bcl.c.mybLoyaltyTargeting = {
  props: {
    componentSelector: ".myb-loyalty-target-JS",
    targetContentSelector: "div[data-level]",
    hiddenClass: "hidden",
  },
  init: function (userData) {
    this.props.loyaltyLevel = parseInt(userData ? (!userData.federationId ? 0 : userData["myb-level"]) : 0);
    document.querySelectorAll(this.props.componentSelector).forEach(
      function ($component) {
        $component.querySelectorAll(this.props.targetContentSelector).forEach(
          function ($targetContent) {
            if (parseInt($targetContent.dataset.level) === this.props.loyaltyLevel) {
              $targetContent.classList.remove(this.props.hiddenClass);
            } else {
              $targetContent.remove();
            }
          }.bind(this),
        );
      }.bind(this),
    );
  },
};
