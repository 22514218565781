(() => {
  const MybBenefit = () => {
    const selectors = {
      component: ".myb-benefit",
      content: ".js-myb-benefit",
      buttonMore: ".js-myb-benefit-more",
      buttonLess: ".js-myb-benefit-less",
      swiperComponent: ".js-swiper-myb-benefit",
      levels: {
        essential: "1",
        special: "2",
        unique: "3",
      },
    };

    const init = () => {
      const parentElement = Array.from(document.querySelectorAll(selectors.component));
      if (parentElement && parentElement.length > 0) {
        const userData = bcl.profile.mapValues.data;
        let level = null;

        if (userData["level"]) {
          level = bcl.u.getKeyByValue(selectors.levels, userData["level"]);
        }

        parentElement.forEach((element) => {
          const content = element.querySelector(selectors.content);
          initComponent(content, level);
        });

        moveBenefitSwiperToCurrentLevel(level);
      }
    };

    const initComponent = (content, level) => {
      const buttonMore = content.querySelector(selectors.buttonMore);
      const buttonLess = content.querySelector(selectors.buttonLess);

      switch (level) {
        case "essential":
          if (content.classList.contains("mod--essential")) {
            content.classList.add("mod--unlock");
          }
          break;

        case "special":
          if (content.classList.contains("mod--essential") || content.classList.contains("mod--special")) {
            content.classList.add("mod--unlock");
          }
          break;

        case "unique":
          if (content.classList.contains("mod--essential") || content.classList.contains("mod--special") || content.classList.contains("mod--unique")) {
            content.classList.add("mod--unlock");
          }
          break;

        default:
          break;
      }

      buttonMore.addEventListener("click", () => {
        content.classList.remove("mod--reverse-flip");
        content.classList.add("mod--flip");
      });

      buttonLess.addEventListener("click", () => {
        content.classList.remove("mod--flip");
        content.classList.add("mod--reverse-flip");
      });
    };

    const moveBenefitSwiperToCurrentLevel = (level) => {
      const swiperComponent = document.querySelectorAll(selectors.swiperComponent);
      if (swiperComponent) {
        swiperComponent.forEach((carousel) => {
          const allSlides = carousel.querySelectorAll(selectors.content);
          let slideIndex = -1;
          allSlides.forEach((slide, index) => {
            if (slide.classList.contains("mod--" + level) && slideIndex == -1) {
              slideIndex = index;
            }
          });

          if (slideIndex != -1) {
            carousel.swiper.slideTo(slideIndex);
          }
        });
      }
    };

    init();
  };
  window.addEventListener("load", MybBenefit);
})();
