import List from "list.js";
import html2pdf from "html2pdf.js/dist/html2pdf.bundle.min.js";

bcl.c.mybBookingHistory = {
  props: {
    component: ".myb-booking-history",
    content: ".js-myb-booking-history",
    rootContent: "js-myb-booking-history",
    tablist: ".js-myb-booking-history-tablist",
    tab: ".js-myb-booking-history-tab",
    tabpanel: ".js-myb-booking-history-tabpanel",
    reservations: [],
    loaded: false,
    count: 0,
  },

  preInit: function () {
    bcl.u.docReady(bcl.c.mybBookingHistory.init);
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document;
    const $component = baseFind.querySelector(bcl.c.mybBookingHistory.props.content);

    if (!$component) {
      return;
    }
    bcl.c.mybBookingHistory.props.endpointPostData = $component.dataset.urlPostData;

    bcl.ajax.postRequest({
      data: bcl.c.mybBookingHistory.props.info,
      url: bcl.c.mybBookingHistory.props.endpointPostData,
      callback: (e) => {
        bcl.c.mybBookingHistory.reservations = [JSON.parse(e.responseText)];
        if (bcl.c.mybBookingHistory.reservations.length > 0) {
          bcl.c.mybBookingHistory.setUpElement();
        }
      },
    });
  },

  setUpElement: function () {
    const parentElement = document.querySelectorAll(bcl.c.mybBookingHistory.props.component);

    if (parentElement && parentElement.length > 0) {
      parentElement.forEach((element) => {
        const component = element.querySelector(bcl.c.mybBookingHistory.props.content);
        const tablist = component.querySelector(bcl.c.mybBookingHistory.props.tablist);

        let data = bcl.c.mybBookingHistory.reservations[0];
        if (data) {
          for (let key in data) {
            let tab = document.createElement("span");
            tab.classList.add("c-myb-booking-history__tab", "js-myb-booking-history-tab");
            tab.textContent = key;

            let tabpanel = document.createElement("div");
            tabpanel.classList.add("c-myb-booking-history__tabpanel");
            tabpanel.dataset.year = key;

            if (data[key].length > 0) {
              tabpanel.classList.add("js-myb-booking-history-tabpanel");
              let list = document.createElement("ul");
              list.classList.add("c-myb-booking-history__list", "list");
              let pagination = document.createElement("ul");
              pagination.classList.add("c-myb-booking-history__pagination", "js-myb-booking-history-pagination");
              pagination.innerHTML = `
                            <span class="prev js-myb-booking-history-button"></span>
                            <ul class="pagination"></ul>
                            <span class="next js-myb-booking-history-button"></span>`;

              data[key].forEach((item) => {
                if (item) {
                  let itemElement = document.createElement("li");
                  itemElement.classList.add("c-myb-booking-history__list__item");
                  let referenceText = component.dataset.locator;
                  let fromText = component.dataset.fromdate;
                  let capitalizedFromText = fromText.charAt(0).toUpperCase() + fromText.slice(1);
                  let toText = component.dataset.articledate;
                  let checkIn = new Date(item["checkIn"]).toLocaleDateString();
                  let checkOut = new Date(item["checkOut"]).toLocaleDateString();
                  let statusText;
                  let repeatText;
                  itemElement.setAttribute("data-map-id", "myb-map-modal");

                  if (item["status"] == "FIR") {
                    statusText = component.dataset.completed;
                    repeatText = component.dataset.availability;
                    itemElement.innerHTML = `
                                    <img class="c-myb-booking-history__list__item-image" src="${item["hotelImage"]}" alt="Hotel Image">
                                    <div class="c-myb-booking-history__list__item-content">
                                        <p class="c-myb-booking-history__list__item-title">${item["hotelName"]}. ${item["roomName"]}</p>
                                        <a class="c-myb-booking-history__list__item-location js-map-button">${item["hotelLocation"]}</a>
                                        <div class="c-myb-booking-history__list__item-details">
                                            <span class="c-myb-booking-history__list__item-status">${statusText}</span>
                                            <span class="c-myb-booking-history__list__item-reference"><span class="c-myb-booking-history__list__item-reference-text">${referenceText}: </span>${item["reservationSynxisCode"]}</span>
                                            <span class="c-myb-booking-history__list__item-date">${capitalizedFromText} ${checkIn} ${toText} ${checkOut}</span>
                                        </div>
                                    </div>
                                    <a class="c-myb-booking-history__list__item-cta" href="${item["hotelPath"]}">${repeatText}</a>`;
                  } else {
                    statusText = component.dataset.cancelled;
                    repeatText = component.dataset.repeat;
                    itemElement.innerHTML = `
                                    <img class="c-myb-booking-history__list__item-image" src="${item["hotelImage"]}" alt="Hotel Image">
                                    <div class="c-myb-booking-history__list__item-content">
                                        <div class="c-myb-booking-history__list__item-head">
                                            <span class="c-myb-booking-history__list__item-reference">${item["reservationSynxisCode"]}</span>
                                            <p class="c-myb-booking-history__list__item-title">${item["hotelName"]}. ${item["roomName"]}</p>
                                        </div>
                                        <a class="c-myb-booking-history__list__item-location js-map-button">${item["hotelLocation"]}</a>
                                        <div class="c-myb-booking-history__list__item-details">
                                            <span class="c-myb-booking-history__list__item-status mod--cancel">${statusText}</span>
                                            <span class="c-myb-booking-history__list__item-reference"><span class="c-myb-booking-history__list__item-reference-text">${referenceText}: </span>${item["reservationSynxisCode"]}</span>
                                            <span class="c-myb-booking-history__list__item-date">${capitalizedFromText} ${checkIn} ${toText} ${checkOut}</span>
                                        </div>
                                    </div>
                                    <a class="c-myb-booking-history__list__item-cta" href="${item["hotelPath"]}">${repeatText}</a>`;
                  }

                  list.append(itemElement);

                  const mapButton = itemElement.querySelector(".js-map-button");
                  let fetchMap = false;

                  if (mapButton) {
                    const urlMap = item["mapDetail"];
                    if (!urlMap) {
                      mapButton.classList.add("mod--disabled");
                    }
                    const initMapNewModal = function () {
                      if (urlMap && !fetchMap) {
                        fetch(urlMap)
                          .then((response) => response.text())
                          .then((data) => {
                            const parser = new DOMParser();
                            const mapDoc = parser.parseFromString(data, "text/html");
                            const mapModal = mapDoc.querySelector(".js-c-hotel-map-card__modal");
                            const pagePosition = window.scrollY;
                            mapModal.classList.add("mod--open");
                            document.body.style.top = "-" + pagePosition + "px";
                            document.body.classList.add("tingle-enabled");
                            const mapContent = mapModal.querySelector(".js-map-content");
                            const closeMapButton = mapModal.querySelector(".js-c-hotel-map-card__modal-close");
                            const expandMapButton = mapModal.querySelector(".js-c-hotel-map-card__modal-expand");
                            itemElement.appendChild(mapModal);
                            bcl.c.mybBookingHistory.initMap(mapContent);
                            bcl.c.mybBookingHistory.modalHandler(mapModal, closeMapButton, pagePosition, expandMapButton);
                            fetchMap = true;
                          });
                      } else if (fetchMap) {
                        const mapModal = itemElement.querySelector(".js-c-hotel-map-card__modal");
                        mapModal.classList.toggle("mod--open");
                        const pagePosition = window.scrollY;
                        document.body.style.top = "-" + pagePosition + "px";
                        document.body.classList.add("tingle-enabled");
                      }
                    };

                    mapButton.addEventListener("click", function () {
                      if (!fetchMap) {
                        bcl.map.ready(initMapNewModal);
                        bcl.map.init();
                      } else {
                        initMapNewModal();
                      }
                    });
                  }
                }
              });

              tabpanel.append(list);
              tabpanel.append(pagination);
              component.append(tabpanel);
            } else {
              tab.setAttribute("title", component.dataset.nobookingthisyear);
              tab.classList.add("mod--disabled");
            }

            tablist.append(tab);
          }
        }

        let emptyData = bcl.c.mybBookingHistory.checkEmptyData(component);

        if (!emptyData) {
          bcl.c.mybBookingHistory.addEvent(component);
          bcl.c.mybBookingHistory.sortTablistAndAddActiveTab(component, tablist);
          bcl.c.mybBookingHistory.addList(component);
        }
      });
    }
  },

  addEvent: function (component) {
    const tabs = component.querySelectorAll(bcl.c.mybBookingHistory.props.tab);
    const tabpanels = component.querySelectorAll(bcl.c.mybBookingHistory.props.tabpanel);

    tabs.forEach((tab) => {
      tab.addEventListener("click", () => {
        if (!tab.classList.contains("mod--disabled")) {
          tabpanels.forEach((tabpanel) => {
            if (tab.textContent == tabpanel.dataset.year) {
              tabpanel.classList.add("mod--active");
            } else {
              tabpanel.classList.remove("mod--active");
            }
          });

          component.querySelector(".js-myb-booking-history-tab.mod--active").classList.remove("mod--active");
          tab.classList.add("mod--active");
        }
      });
    });

    const loader = component.querySelector(".js-loader");
    const downloadButtons = component.querySelectorAll(".js-download-button");
    if (downloadButtons) {
      downloadButtons.forEach((downloadButton) => {
        downloadButton.addEventListener("click", function () {
          const year = component.querySelector(".js-myb-booking-history-tab.mod--active").textContent;
          const document = bcl.c.mybBookingHistory.generatePDFContent(year, component);
          loader.style.display = "flex";

          if (document) {
            const options = {
              filename: year + "_bookings.pdf",
              image: { type: "jpeg", quality: 1 },
              html2canvas: {
                scale: 2,
                useCORS: true,
                logging: true,
                scrollX: 0,
                scrollY: 0,
              },
              jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };
            html2pdf()
              .set(options)
              .from(document)
              .save()
              .then(function () {
                loader.style.display = "";
              });
          }
        });
      });
    }

    const printButtons = component.querySelectorAll(".js-print-button");

    printButtons.forEach((printButton) => {
      printButton.addEventListener("click", function () {
        const year = component.querySelector(".js-myb-booking-history-tab.mod--active").textContent;
        const document = bcl.c.mybBookingHistory.generatePDFContent(year, component);
        loader.style.display = "flex";

        if (document) {
          const options = {
            filename: year + "_bookings.pdf",
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              scale: 1,
              useCORS: true,
              logging: true,
              scrollX: 0,
              scrollY: 0,
            },
            jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            pagebreak: { mode: "css" },
          };

          html2pdf()
            .set(options)
            .from(document)
            .outputPdf("dataurlnewwindow")
            .then(function () {
              loader.style.display = "";
              // const newWindow = window.open("", "PRINT");
              // if (newWindow) {
              //   newWindow.document.write(`<iframe width="100%" height="100%" src="${pdf}"></iframe>`);
              //   newWindow.document.close();
              //   newWindow.focus();
              //   newWindow.print();
              //   newWindow.close();
              // }
            });
        }
      });
    });
  },

  generatePDFContent: function (year, component) {
    const data = bcl.c.mybBookingHistory.reservations[0];
    if (data) {
      for (let key in data) {
        if (key == year) {
          let pdfContent = "<ul style='display: flex; flex-direction: column; gap: 24px 0; padding: 32px;'>";
          const referenceText = component.dataset.locator;
          const fromText = component.dataset.fromdate;
          const capitalizedFromText = fromText.charAt(0).toUpperCase() + fromText.slice(1);
          const toText = component.dataset.articledate;
          let statusText;

          data[key].forEach((item) => {
            let checkIn = new Date(item["checkIn"]).toLocaleDateString();
            let checkOut = new Date(item["checkOut"]).toLocaleDateString();

            if (item["status"] == "FIR") {
              statusText = component.dataset.completed;

              pdfContent += `<li style="border: 1px solid lightgray; border-radius: 8px; display: flex; flex-direction: column; padding: 13px 24px 13px; break-inside: avoid;">
                <p style="font-size: 18px; font-weight: 600; line-height: 30px; text-decoration: underline;">${item["hotelName"]}. ${item["roomName"]}</p>
                <span style="font-size: 14px; font-weight: 300; line-height: 24px; margin: 8px 0; display: inline-flex; align-items: center; align-self: flex-start;">
                  <img src="${location.origin + "/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo/resources/images/booking/location.svg"}" style="width: 22px; height: 16px; display: block; margin-right: 4px; padding: 0 5px; object-fit: contain;"/>
                  ${item["hotelLocation"]}
                </span>
                <div style="display: flex; flex-wrap: wrap; gap: 16px; align-items: center;">
                    <span style="font-size: 10px; font-weight: 400; line-height: 16px; padding: 4px 8px; background-color: #EAEEF1; display: inline-block; border-radius: 8px;">${statusText}</span>
                    <span style="font-size: 10px; font-weight: 600; line-height: 16px; color: #606669;">
                      <span style="font-weight: 400;">${referenceText}: </span>
                      ${item["reservationSynxisCode"]}
                    </span>
                    <span style="font-size: 14px; font-weight: 300; line-height: 24px; display: inline-block; width: auto;">${capitalizedFromText} ${checkIn} ${toText} ${checkOut}</span>
                </div>
              </li>`;
            } else {
              statusText = component.dataset.cancelled;

              pdfContent += `<li style="border: 1px solid lightgray; border-radius: 8px; display: flex; flex-direction: column; padding: 13px 24px 13px; break-inside: avoid;">
                <div style="display: flex; align-items: center;">
                  <span style="font-size: 10px; font-weight: 600; margin-right: 8px;">${item["reservationSynxisCode"]}</span>
                  <p style="font-size: 18px; font-weight: 600; line-height: 30px; text-decoration: underline;">${item["hotelName"]}. ${item["roomName"]}</p>
                </div>  
                <span style="font-size: 14px; font-weight: 300; line-height: 24px; margin: 8px 0; display: inline-flex; align-items: center; align-self: flex-start;">
                  <img src="${location.origin + "/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo/resources/images/booking/location.svg"}" style="width: 22px; height: 16px; display: block; margin-right: 4px; padding: 0 5px; object-fit: contain;"/>
                  ${item["hotelLocation"]}
                </span>
                <div style="display: flex; flex-wrap: wrap; gap: 16px; align-items: center;">
                    <span style="font-size: 10px; font-weight: 400; line-height: 16px; padding: 4px 8px; background-color: #FCE7E7; display: inline-block; border-radius: 8px;">${statusText}</span>
                    <span style="font-size: 10px; font-weight: 600; line-height: 16px; color: #606669;">
                      <span style="font-weight: 400;">${referenceText}: </span>
                      ${item["reservationSynxisCode"]}
                    </span>
                    <span style="font-size: 14px; font-weight: 300; line-height: 24px; display: inline-block; width: auto;">${capitalizedFromText} ${checkIn} ${toText} ${checkOut}</span>
                </div>
              </li>`;
            }
          });

          pdfContent += "</ul>";

          return pdfContent;
        }
      }
    }
  },

  addList: function (component) {
    const tabpanels = component.querySelectorAll(bcl.c.mybBookingHistory.props.tabpanel);
    const maxItem = component.dataset.maxItem;
    tabpanels.forEach((tabpanel) => {
      let list = tabpanel.querySelector(".list");

      if (list && list.children.length > maxItem) {
        tabpanel.id = "myb-booking-history-" + bcl.c.mybBookingHistory.props.count;
        bcl.c.mybBookingHistory.props.count++;

        let id = tabpanel.id;

        new List(id, {
          page: maxItem,
          pagination: {
            innerWindow: 3,
          },
        });

        bcl.c.mybBookingHistory.paginationHandler(tabpanel);
      } else {
        tabpanel.classList.add("mod--hide-pagination");
      }
    });
  },

  sortTablistAndAddActiveTab: function (component, tablist) {
    let tabs = component.querySelectorAll(bcl.c.mybBookingHistory.props.tab);

    if (tabs && tabs.length > 0) {
      tabs = Array.from(tabs).sort(function (a, b) {
        return b.textContent - a.textContent;
      });

      let firstNotDisableTab = true;

      tabs.forEach((tab) => {
        tablist.append(tab);

        if (!tab.classList.contains("mod--disabled") && firstNotDisableTab) {
          firstNotDisableTab = false;
          tab.classList.add("mod--active");
          component.querySelector(`.js-myb-booking-history-tabpanel[data-year='${tab.textContent}']`).classList.add("mod--active");
        }
      });
    }
  },

  checkEmptyData: function (component) {
    let tabs = component.querySelectorAll(bcl.c.mybBookingHistory.props.tab);
    let disabledTabs = component.querySelectorAll(bcl.c.mybBookingHistory.props.tab + ".mod--disabled");

    let empty = false;

    if (tabs.length == disabledTabs.length) {
      component.classList.add("mod--empty");
      empty = true;
    }

    return empty;
  },

  paginationHandler: function (tabpanel) {
    const paginationList = tabpanel.querySelector(".js-myb-booking-history-pagination .pagination");
    const paginationButton = tabpanel.querySelectorAll(".js-myb-booking-history-button");

    paginationButton.forEach((button) => {
      button.addEventListener("click", () => {
        let activeIndex = tabpanel.querySelector(".active a").dataset.i;
        if (button.classList.contains("prev")) {
          activeIndex--;
        } else if (button.classList.contains("next")) {
          activeIndex++;
        }

        if (activeIndex > 0 && activeIndex <= paginationList.children.length) {
          paginationList.querySelector(".active").classList.remove("active");
          paginationList.querySelector(`[data-i='${activeIndex}']`).click();
        }
      });
    });
  },

  initMap: function (component) {
    const lat = component.getAttribute(bcl.c.mybCurrentBooking.props.nameLatAttr),
      lng = component.getAttribute(bcl.c.mybCurrentBooking.props.nameLngAttr);
    let zoom = parseInt(component.dataset.zoom);

    let modalMap = component.querySelector(".js-c-hotel-map-card__modal__map-wrapper");

    if (!zoom) {
      zoom = 12;
    }

    const point = new google.maps.LatLng(lat, lng);
    let centerMap = point;

    const map = new google.maps.Map(modalMap, {
      center: centerMap, // -0.1
      scrollwheel: true,
      zoom: zoom,
      fullscreenControl: false,
      minZoom: false,
      maxZoom: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      styles: bcl.map.props.mapStyles.defaultStyle,
    });

    new google.maps.Marker({
      map: map,
      position: point,
      icon: new google.maps.MarkerImage("/content/dam/barcelo/commons/icons/statics/poi_barcelo__black-B.svg", null, null, null, new google.maps.Size(48, 48)),
    });

    google.maps.event.addListenerOnce(map, "idle", function () {
      google.maps.event.trigger(map, "resize");
      map.setCenter(point);
    });
  },

  modalHandler: function ($modal, $closeModal, pagePosition, $expandButton) {
    if ($expandButton) {
      $expandButton.addEventListener("click", () => {
        $modal.classList.toggle("mod--expand");
      });
    }

    $modal.addEventListener("click", (e) => {
      if (e.target.isEqualNode($modal)) {
        $modal.classList.remove("mod--open");
        document.body.classList.remove("tingle-enabled");
        window.scrollTo(0, pagePosition);
        document.body.style.top = null;
      }
    });

    $closeModal.addEventListener("click", () => {
      $modal.classList.remove("mod--open");
      document.body.classList.remove("tingle-enabled");
      window.scrollTo(0, pagePosition);
      document.body.style.top = null;
    });
  },
};

bcl.c.mybBookingHistory.preInit();
