(() => {
  const MybBannerAnimated = () => {
    const selectors = {
      component: ".myb-banner-animated",
      content: ".js-banner-animated",
      bannerImage: ".js-banner-animated-image",
    };

    const scrollHandler = (content) => {
      if (window.innerWidth < 1025) {
        if (content.getBoundingClientRect().y > -20 && content.getBoundingClientRect().y < window.innerHeight - 100) {
          content.classList.add("mod--number");
        } else {
          content.classList.remove("mod--number");
        }
      } else {
        content.classList.remove("mod--number");
      }
    };

    const init = () => {
      const parentElement = Array.from(document.querySelectorAll(selectors.component));
      parentElement.forEach((element) => {
        const content = element.querySelector(selectors.content);
        const bannerImage = element.querySelector(selectors.bannerImage);
        const bannerImageContent = bannerImage.querySelector(".cmp-image__image");

        if (bannerImageContent) {
          bannerImage.style.maskImage = "url('" + bannerImageContent.src + "')";
        }

        window.addEventListener("resize", () => {
          scrollHandler(content);
        });

        window.addEventListener("scroll", () => {
          scrollHandler(content);
        });
      });
    };

    init();
  };
  window.addEventListener("load", MybBannerAnimated);
})();
