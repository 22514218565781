bcl.c.mybBanner = {
  props: {
    componentSelector: ".c-myb-banner-JS",
    bannerSelector: "div[data-loyalty-level]",
    hiddenClass: "hiddenVisual",
  },
  init: function (userData) {
    this.props.loyaltyLevel = parseInt(userData ? (!userData.federationId ? 0 : userData["myb-level"]) : 0);
    document.querySelectorAll(this.props.componentSelector).forEach(
      function ($component) {
        $component.querySelectorAll(this.props.bannerSelector).forEach(
          function ($banner) {
            if (parseInt($banner.dataset.loyaltyLevel) === this.props.loyaltyLevel) {
              $banner.classList.remove(this.props.hiddenClass);
            } else {
              $banner.remove();
            }
          }.bind(this),
        );
      }.bind(this),
    );
  },
};
