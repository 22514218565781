bcl.map = {
  props: {
    readyState: false,
    nameInitEvent: "barcelo-initMap",
    nameAttr: "data-map-id",
    url: "https://maps.googleapis.com/maps/api/js",
    loaded: false,
  },
  preInt: function () {
    //No inizialized on DOM load
    //bcl.u.docReady(bcl.map.init);
  },
  init: function (baseDom) {
    if (bcl.map.props.loaded) {
      bcl.map.loaded();
      return;
    }

    if (googleApiKey) {
      const baseFind = baseDom ? baseDom : document;
      const elements = baseFind.querySelectorAll(bcl.map.getSelectorAttr());

      if (elements && elements.length > 0 && !bcl.map.props.readyState) {
        bcl.map.props.readyState = true;

        bcl.u.loadScript({
          src: bcl.map.props.url + "?key=" + googleApiKey,
          type: "text/javascript",
          callback: bcl.map.loaded,
          defer: true,
        });
      }
    }
  },

  getSelectorAttr: function (value) {
    if (value) {
      value = '="' + value + '"';
    } else {
      value = "";
    }

    return "[" + bcl.map.props.nameAttr + value + "]";
  },

  loaded: function () {
    bcl.map.props.loaded = true;
    document.dispatchEvent(new Event(bcl.map.props.nameInitEvent));
  },

  ready: function (callback) {
    if (bcl.map.props.readyState) {
      callback();
    } else {
      document.addEventListener(bcl.map.props.nameInitEvent, callback);
    }
  },
  smoothZoom: function (map, targetZoom) {
    const currentZoom = arguments[2] || map.getZoom();
    if (currentZoom != targetZoom) {
      google.maps.event.addListenerOnce(map, "zoom_changed", function () {
        bcl.map.smoothZoom(map, targetZoom, currentZoom + (targetZoom > currentZoom ? 1 : -1));
      });
      setTimeout(function () {
        map.setZoom(currentZoom);
      }, 80);
    }
  },
  popupClass: function () {
    /**
     * A customized popup on the map.
     * @param {!google.maps.LatLng} position
     * @param {!Element} content The bubble div.
     * @constructor
     * @extends {google.maps.OverlayView}
     */
    const Popup = function (position, content) {
      this.position = position;

      bcl.u.addClass(content, "popup-bubble");

      // This zero-height div is positioned at the bottom of the bubble.
      const bubbleAnchor = document.createElement("div");
      bcl.u.addClass(bubbleAnchor, "popup-bubble-anchor");
      bubbleAnchor.appendChild(content);

      // This zero-height div is positioned at the bottom of the tip.
      this.containerDiv = document.createElement("div");
      bcl.u.addClass(this.containerDiv, "popup-container");
      this.containerDiv.appendChild(bubbleAnchor);

      if (content.dataset.popupCallback) {
        bcl.u.executeFunctionByName(content.dataset.popupCallback, window, content);
      }

      // Optionally stop clicks, etc., from bubbling up to the map.
      google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
    };
    // ES5 magic to extend google.maps.OverlayView.
    Popup.prototype = Object.create(google.maps.OverlayView.prototype);

    /** Called when the popup is added to the map. */
    Popup.prototype.onAdd = function () {
      this.getPanes().floatPane.appendChild(this.containerDiv);
    };

    /** Called when the popup is removed from the map. */
    Popup.prototype.onRemove = function () {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv);
      }
    };

    /** Called each frame when the popup needs to draw itself. */
    Popup.prototype.draw = function () {
      const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

      // Hide the popup when it is far out of view.
      const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? "block" : "none";

      if (display === "block") {
        this.containerDiv.style.left = divPosition.x + "px";
        this.containerDiv.style.top = divPosition.y + "px";
      }
      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
    };

    return Popup;
  },
};

bcl.preInit.push(bcl.map.preInt);
